<template>
  <div>
    <main-header />
    <section
        style="min-height:400px"
      class=" w-100 d-flex flex-column justify-content-center align-items-center text-center"
    >
      <h1 class="display-3"><b>404</b></h1>
      <h6>La page ou l'article recherché n'existe pas</h6>
      <br>
      <router-link to="/"><u>Retourner à l'acceuil</u></router-link>
    </section>
    <main-footer />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
